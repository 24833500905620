.card {
  position: relative;
  background:white;
  box-shadow: 0px 2px 4px 1px #dadadac7;
  border-radius: 15px;
  transition: .5s ease-in;
}
.card__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding:20px 20px; 
  height:100%;
}
.card .title {
  width: 80%;
  overflow: hidden;
}
.card .into:nth-child(1) div:nth-child(1) {
  display:flex;
  justify-content: space-between;
}
.time {
  display: flex;
}
.card .delete {
  cursor: pointer;
}
.card svg {
  color: #535353;
}
.into__down {
  display: flex;
  margin-top: 25px;
}
.into__down h4 {
  color:#4a4a4a;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size:13px;
  letter-spacing: -1px;
}
.into__down .flag {
  position: absolute;
  left:45%;
}
.into__down .comp svg{
  color: rgb(131, 7, 7);
  cursor: pointer;
}
.orange {
  color:#f88808 !important;
}
.green {
  color: #37782C !important;
}
.red {
  color:#C91010 !important;
}
.text {
  width: 95%;
  height:85px;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-top: 15px;
}
.text::-webkit-scrollbar {
  width: 7px;
}
.text::-webkit-scrollbar-track {
  background: #F4F3F3; 
  border-radius:15px;
}
.text::-webkit-scrollbar-thumb {
  background: #C4C4C4; 
  border-radius:15px;
}

.scroll {
  overflow-y: scroll !important;
}
.noscroll {
  overflow-y: hidden !important;
}

.card h2{
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  color: #535353;
  font-weight: 800;
}
.card h4{
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  position: relative;
  left: 5px;
  top:-3px;
  color: #4A4A4A;
}
.card p{
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 300;
  color: #4E4D4D;
}
.completed {
  width:15px; 
  height: 18px;
  border: 2px solid #C4C4C4;
  background-color: white !important;
  position: absolute;
  left:55%;
  cursor: pointer;
  border-radius: 0px 0px 50px 50px;
}
.gray {
  background-color:  #C4C4C4 !important;
}

@media(max-width: 780px) {
  .into__down {
    margin-top: 5px;
  }
  .into__down h4 {
    font-size:12px;
    position: relative;
    top:-1px;
  }
  .into__down .flag {
    left:55%;
  }
  .completed {
    left:70%;
  }
  .text {
    width: 95%;
    height:85px;
    overflow-y: hidden;
    overflow-x: hidden;
    margin-top: 15px;
  }
}