#update-profile {
  overflow: hidden;
}
#update-profile .container {
  padding:20px 50px;
}
#update-profile h2 {
  font-family: 'Rubik', sans-serif;
  font-size:40px;
  color: #03015D;
  padding-bottom: 20px;
}
#update-profile .container form input {
  font-family: "Poppins", sans-serif;
  font-weight: 400; 
  width: 25vw;
  padding: 1.8vh 15px;
  border: 1px solid #C4C4C4;
  border-radius: 7px;
  margin-top: 10px;
  outline: none;
  font-size: 15px;
}
#update-profile .container button {
  font-family: "Poppins", sans-serif;
  font-weight: 500; 
  width: 25vw;
  padding: 1.8vh 15px;
  border: 1px solid #C4C4C4;
  border-radius: 7px;
  margin-top: 10px;
  outline: none;
  font-size: 15px;
  background:  #03015D;
  color:white;
  cursor: pointer;
}
#update-profile .container form {
  display:flex;
  justify-content: center;
  flex-direction: column;
  margin-top:25px;
}
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
  z-index: -1;
}
.inputfile + label {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 400;
  border-radius:5px;
  color: white;
  background-color: #03015D;
  display: inline-block;
  margin-top:15px;
  padding:5px 10px;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #03023a;
}
.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

@media(max-width: 1100px) {
  #update-profile .container form input {
    width: 300px;
  }
  #update-profile .container button {
    width: 300px;
  }
}
.bg-prf {
  width: 75px !important;
  height: 75px !important;
}
@media(max-width: 500px) {
  #update-profile .container form input {
    width: 100%;
  }
  #update-profile .container button {
    width: 100%;
  }
}
@media(min-width: 1600px) {
  #update-profile .container {
    padding:50px 50px;
  }
  #update-profile h2 {
    font-size:50px;
    padding-bottom: 25px;
  }
  .bg-prf {
    width: 85px !important;
    height: 85px !important;
  }
  .inputfile + label {
    margin-top:25px;
  }
}